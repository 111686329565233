import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState, useMemo } from 'react';
import excelClipboardCopy from '../../hooks/excelClipboardCopy';
import BasicButton from '../NPSportal/basicButton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../CSS/EditableGrid.css';
import UploadFile from '../Shared/UploadFile';
import dayjs from 'dayjs';

const EditableGrid = ({
  columns,
  data,
  title,
  close = false,
  tableInfo,
  saveData,
  headerComponent,
  customFilter,
  buttonComponent,
  customRows = [],
}) => {
  const [rowData, setRowData] = useState(
    data.map((row) => ({
      ...row,
      _original: { ...row },
    }))
  );
  const [modifiedRows, setModifiedRows] = useState([]);
  const [batchImportData, setBatchImportData] = useState();
  const [saveEditData, setSaveEditData] = useState();

  const gridRef = useRef(null);
  const copyToClipboard = excelClipboardCopy();

  const columnDefs = columns?.map((column) => {
    const isEditable = column.editable === 'true';

    return {
      ...Object.fromEntries(
        Object.entries(column).map(([key, value]) => [
          key,
          value === 'true' ? true
          : value === 'false' ? false
          : value,
        ])
      ),
      width:
        columns?.length > 15 ? 110
        : columns?.length <= 7 ? 210
        : 130,
      ...(isEditable && {
        cellClassRules: {
          'cell-changed': (params) => {
            const originalValue = params.data._original?.[params.colDef.field];
            return originalValue !== undefined && params.value !== originalValue;
          },
        },
      }),
      filterParams: {
        comparator: (filterDate, cellValue) => {
          // Apply this logic only when cellDataType is date
          if (typeof cellValue === 'string' || cellValue instanceof Date) {
            const cellDate = new Date(cellValue); // Convert string to Date
            if (cellDate < filterDate) {
              return -1;
            }
            if (cellDate > filterDate) {
              return 1;
            }
            return 0;
          }
          return 0; // Default case if not a date
        },
      },

      // valueGetter: (params) => {
      //   if (params.column.cellDataType === 'date') {
      //     return new Date(params.data.date); // Convert string to Date if it's a date column
      //   }
      //   return params.value; // Return the original value for other data types
      // },
      // valueFormatter: (params) => {
      //   if (column.cellDataType === 'date') {
      //     return params.value ? params.value.toLocaleDateString() : ''; // Format for display
      //   }
      //   return params.value;
      // },
      cellRenderer: (params) => {
        if (column.cellDataType === 'button') {
          return buttonComponent({ data: params.data });
        }
        if (column.cellDataType === 'date') {
          return params.value ? dayjs(params?.value).format('MM/DD/YYYY') : ''; // Format for display
        }
        return params.value;
      },
    };
  });

  const rowClassRules = {
    'row-duplicate': (params) => params.data.isDuplicate,
  };

  const mergedRowClassRules = customRows.reduce(
    (acc, { condition, className }) => {
      if (condition) {
        acc[className] = (params) => condition(params.data);
      }
      return acc;
    },
    { ...rowClassRules }
  );

  const gridOptions = {
    rowData: rowData,
    enableCellTextSelection: true,
    onCellValueChanged: (e) => onCellChanged(e),
    animateRows: true,
    editType: 'fullRow',
    columnDefs: columnDefs,
    rowClassRules: customRows ? mergedRowClassRules : rowClassRules,
  };

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
      checkboxes: true,
      headerCheckbox: true,
      enableClickSelection: true,
    };
  }, []);

  const duplicateSelectedRows = () => {
    if (!gridRef?.current?.api) {
      console.error('Grid API is not available');
      return;
    }

    const selectedNodes = gridRef.current.api.getSelectedNodes();
    if (selectedNodes.length === 0) {
      console.error('No rows selected to duplicate');
      return;
    }

    const sortedNodes = selectedNodes.sort((a, b) => a.rowIndex - b.rowIndex);

    const duplicatedRows = sortedNodes.map((node) => {
      const selectedData = node.data;
      return { ...selectedData, id: generateUniqueId(), isDuplicate: true };
    });

    const newRowData = [...rowData, ...duplicatedRows];
    setRowData(newRowData);

    duplicatedRows.forEach((row, i) => {
      gridRef.current.api.applyTransaction({
        add: [row],
        addIndex: sortedNodes[i].rowIndex + 1,
      });
    });

    setModifiedRows((prevModifiedRows) => [...prevModifiedRows, ...duplicatedRows]);
    const originalRowIds = selectedNodes.map((node) => node.id);
    gridRef.current.api.forEachNode((node) => {
      if (originalRowIds.includes(node.id)) {
        node.setSelected(true);
      }
    });
    gridRef.current.api.refreshCells({ force: true });
  };

  const deleteSelectedDuplicateRows = () => {
    if (!gridRef?.current?.api) {
      console.error('Grid API is not available');
      return;
    }

    // Get selected rows
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedRows = selectedNodes.map((node) => node.data);

    // Filter out rows that are duplicates and selected
    const rowsToKeep = rowData.filter(
      (row) => !selectedRows.some((selectedRow) => selectedRow.id === row.id && row.isDuplicate)
    );

    // Set the new row data
    setRowData(rowsToKeep);

    // Remove the selected duplicate rows from the grid
    const rowsToRemove = rowData.filter((row) =>
      selectedRows.some((selectedRow) => selectedRow.id === row.id && row.isDuplicate)
    );

    gridRef.current.api.applyTransaction({ remove: rowsToRemove });

    // Update modified rows state
    setModifiedRows((prevModifiedRows) =>
      prevModifiedRows.filter(
        (row) => !selectedRows.some((selectedRow) => selectedRow.id === row.id && row.isDuplicate)
      )
    );
  };

  const onCellChanged = (event) => {
    var updatedRow = event.data;

    setModifiedRows((prevModifiedRows) => {
      const rowExists = prevModifiedRows.find((row) => row.id === updatedRow.id);

      if (rowExists) {
        return prevModifiedRows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
      }

      const id = updatedRow.id || updatedRow.unique_id || generateUniqueId();
      updatedRow.id = id;

      const newRowData = rowData.map((row) => (row.id === updatedRow.id ? updatedRow : row));

      setRowData(newRowData);

      gridRef.current.api.refreshCells({ rowNodes: [updatedRow] });

      return [...prevModifiedRows, updatedRow];
    });
  };

  const copySelectedRowsToClipboard = () => {
    const isTextSelected = window.getSelection().toString().length > 0;

    if (isTextSelected) {
      return;
    }
    const selectedNodes = gridRef.current.api.getSelectedNodes();

    const allColumns = gridRef.current.api.getColumns();
    const visibleColumns = allColumns.filter((col) => col.getColDef().hide !== true);

    const columnOrder = visibleColumns.map((col) => col.getColId());
    const selectedData = selectedNodes.map((node) => node.data);

    const textToCopy = selectedData
      .map((row) =>
        columnOrder
          .map((colId) => {
            const value = row[colId];
            return `"${value}"`;
          })
          .join('\t')
      )
      .join('\r\n');

    copyToClipboard(textToCopy);
  };

  function generateUniqueId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 8;
    let uniqueId = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters[randomIndex];
    }

    return uniqueId;
  }

  function saveView() {
    setSaveEditData(modifiedRows);
  }

  const exportToCSV = () => {
    gridRef.current.api.exportDataAsCsv({
      skipHeader: false,
      columnGroups: true,
      allColumns: false,
    });
  };

  const applyCustomFilter = () => {
    if (gridRef.current?.api && customFilter) {
      gridRef.current.api.setFilterModel({
        // Apply the custom filter to the specific column
        [customFilter.column]: {
          type: customFilter.type,
          filter: customFilter.filter,
        },
      });
    } else if (gridRef.current?.api) {
      // Clear the filters if no custom filter is provided
      gridRef.current.api.setFilterModel(null);
    }
  };

  useEffect(() => {
    if (gridRef.current) {
      applyCustomFilter();
    }
  }, [customFilter]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        copySelectedRowsToClipboard();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (batchImportData) {
      saveData(batchImportData);
      setBatchImportData();
    }
  }, [batchImportData]);

  useEffect(() => {
    if (batchImportData) {
      saveData(batchImportData, false);
      setBatchImportData();
    }
  }, [batchImportData]);

  useEffect(() => {
    if (saveEditData) {
      saveData(saveEditData, true);
      setSaveEditData();
    }
  }, [saveEditData]);

  return (
    <>
      <div style={{ width: '80%', margin: 'auto', position: 'relative' }}>
        {close ?
          <div
            role='button'
            aria-label='Back to previous page'
            tabIndex={0}
            onClick={() => {
              close();
            }}
            className='laminates-back-button'
            style={{ color: 'var(--darkblue)' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                close();
              }
            }}
          >
            {'< Back'}
          </div>
        : ''}
        {headerComponent ?
          headerComponent
        : <div className={`in-stock-table-header`}>
            <div
              style={{ alignItems: 'end' }}
              className='basic-flex'
            >
              <h2>{title.charAt(0).toUpperCase() + title.slice(1)}</h2>
            </div>
            <div className='basic-flex'>
              {tableInfo?.duplicate_rows ?
                <BasicButton
                  type={'basic-white-button'}
                  text={'Duplicate Rows'}
                  onClick={duplicateSelectedRows}
                />
              : ''}
              {tableInfo?.duplicate_rows ?
                <BasicButton
                  text='Delete Selected Duplicates'
                  type='basic-white-button'
                  onClick={deleteSelectedDuplicateRows}
                />
              : ''}
              {tableInfo?.export ?
                <BasicButton
                  text={'Export'}
                  type={'basic-white-button'}
                  onClick={() => exportToCSV()}
                />
              : ''}
              {tableInfo?.import_batch_update ?
                <UploadFile
                  text={'Import Batch Update'}
                  setData={setBatchImportData}
                  className={'basic-white-button basic-button-design'}
                />
              : ''}
              {tableInfo?.update_data ?
                <BasicButton
                  text={'Save Data'}
                  type={'basic-green-button'}
                  onClick={saveView}
                />
              : ''}
            </div>
          </div>
        }

        <div
          className='ag-theme-alpine'
          style={{ height: 650, margin: 'auto' }}
        >
          <AgGridReact
            pagination={true}
            paginationPageSize={50}
            ref={gridRef}
            gridOptions={gridOptions}
            rowSelection={rowSelection}
            frameworkComponents={{
              ButtonRenderer: buttonComponent,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EditableGrid;
